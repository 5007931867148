@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&family=Poppins:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,400;1,9..40,100&family=Lato:wght@300;400&family=Poppins:wght@400;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

@layer base{
  body{
    @apply box-border overflow-x-hidden;
  }
  h1{
    @apply font-heading text-5xl lg:text-6xl lg:pr-3 px-0 py-2 font-semibold;
  }
  h2{
    @apply text-3xl text-center lg:text-start lg:text-4xl px-6 lg:px-0 py-6 lg:py-0 font-semibold;
  }
  h3{
    @apply text-xl sm:text-2xl font-bold;
  }
  h4{
    @apply text-lg sm:text-xl font-semibold;
  }
  mark{
    @apply text-deepBlue bg-transparent;
  }
  span mark{
    @apply text-[1.1em];
  }
  p{
    @apply font-body text-lg sm:text-base;
  }
  b{
    @apply font-semibold;
  }
  .btn{
    @apply font-btn cursor-pointer bg-midBlue text-white font-bold text-base sm:text-xl rounded-full px-6 pt-1 pb-1 my-6 sm:my-6 hover:bg-midGreen hover:text-dark transition-all duration-300 ease-in-out;
  }
  .app-store-icon{
    @apply cursor-pointer w-36;
  }
  .only-mobile{
    @apply block lg:hidden;
  }
  .only-desktop{
    @apply hidden lg:block;
  }
}