/*Vertical Sliding*/
.slidingVertical{
    /*display: inline;*/
    display: block;
    /*text-indent: 8px;*/
    height: 120px;
    /*position: relative;*/
    line-height: 1.0em;
    margin: 0;
    text-transform: uppercase;
}
/* mobile view */
@media screen and (max-width: 768px) {
    .slidingVertical{
        display: block;
        height: 150px;
    }
}

.slidingVertical span{
    animation: topToBottom 12.5s linear infinite 0s;
    color: #1529c3;
    opacity: 0;
    overflow: hidden;
    position: absolute;
}
.slidingVertical span:nth-child(2){
    animation-delay: 2.5s;
    -webkit-animation-delay: 2.5s;
}
.slidingVertical span:nth-child(3){
    animation-delay: 5s;
    -webkit-animation-delay: 5s;
}
.slidingVertical span:nth-child(4){
    animation-delay: 7.5s;
    -webkit-animation-delay: 7.5s;
}
.slidingVertical span:nth-child(5){
    animation-delay: 10s;
    -webkit-animation-delay: 10s;
}

/*topToBottom Animation*/
@keyframes topToBottom{
    0% { opacity: 0; }
    5% { opacity: 0; transform: translateY(-90px); }
    10% { opacity: 1; transform: translateY(0px); }
    25% { opacity: 1; transform: translateY(0px); }
    28% { opacity: 0; transform: translateY(50px); }
    80% { opacity: 0; }
    100% { opacity: 0; }
}